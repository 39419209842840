<template>
<div class="newdetails page">
  <Headers title="详情"></Headers>
  <div class="scroll">
    <h2>{{title}}</h2>
    <div class="content" v-html="content"></div>
  </div>
</div>
</template>

<script>
import Headers from "@/components/headers.vue";
import {newdetails} from "@/axios/api";
export default {
  name: "newdetails",
  components: {
    Headers
  },
  data() {
    return {
      title:"",
      content:"",
      id: ''
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getInfo()
  },
  methods: {
    getInfo(){
      $api.newdetails({
        id: this.id,
        token: this.$store.getters["getToken"],
      }).then(res => {
        this.title = res.data.title;
        this.content = res.data.content;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.newdetails {
  .scroll {
    padding: 0 15px;
  }
  h2 {
    font-size: 24px;
    padding: 20px;
  }
  .content {
    font-size: 16px;
    text-align: left;
  }
}
</style>